import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout, { layoutContext } from "../components/layout"
import Seo from "../components/seo"



const ServicesPage = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title="Services" />
              <div className="container mt-16 lg:mt-[100px]">
                <div className="m-auto max-w-[750px] text-center">
                  <h1 className="text-h3 lg:text-h2 font-bold mb-8">Make A Move Without The Stress! </h1>
                  <p className="mb-[70px]">You've got enough going on without having to worry about your movers not showing up, losing all your belongings in transit, or having a bad back from lugging all those heavy boxes yourself. That's why we are here to help!</p>
                </div>


                <div className="grid lg:grid-cols-2 lg:gap-x-16 lg:gap-y-16 lg:mt-32 lg:mb-32">
                  <div className="order-1 hidden lg:block">
                    <StaticImage src={'../images/services_long.png'} layout="fullWidth" />
                  </div>
                  <div className="order-2 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Long Distance Residential Moving</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">We're a full-service moving company that covers 48 states (except AK &HI), and we use only the most experienced carriers with 5-star ratings. If you are looking for a cross-country mover who will provide top-notch service, look no further!</p>
                    <Link to="/long-distance-residential-moving" className="font-bold m-link-arrow">Learn More</Link>
                  </div>



                  <div className="order-3 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Long-Distance Auto Transport</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">Eliminate wear and tear on your car and extra miles added to the odometer. We’ll help you safely move your car with long-distance vehicle transport along with all your other belongings while moving from state to state, coast to coast, or any other long-distance location.</p>
                    <Link to="/long-distance-residential-moving" className="font-bold m-link-arrow">Learn More</Link>
                  </div>

                  <div className="order-4 hidden lg:block">
                    <StaticImage src={'../images/auto_transport.png'} layout="fullWidth" />
                  </div>

                  <div className="order-5 hidden lg:block">
                    <StaticImage src={'../images/piano_moving.png'} layout="fullWidth" />
                  </div>

                  <div className="order-6 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Long-Distance Piano Movers</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">Whether you need to move your Upright, Baby Grand, Grand, or any other heavy musical instrument, you can count on us to handle your long-distance piano move with the utmost care and ease for a damage-free piano moving experience.</p>
                    <Link to="/premium-packing" className="font-bold m-link-arrow">Learn More</Link>
                  </div>

                  <div className="order-7 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Heavy & Specialty Item Moving</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">
                      Let us handle the heavy lifting when it comes to moving your valuable, heavy, collectible, or fragile personal property. We are fully licensed and experienced Specialty Movers who take the utmost care to ensure your specialty items arrive at their destination safely, on time, and without any damage.
                    </p>
                    <Link to="/premium-packing" className="font-bold m-link-arrow">Learn More</Link>
                  </div>

                  <div className="order-8 hidden lg:block">
                    <StaticImage src={'../images/specialty_moving.png'} layout="fullWidth" />
                  </div>

                  <div className="order-9 hidden lg:block">
                    <StaticImage src={'../images/services_packing.png'} layout="fullWidth" />
                  </div>
                  <div className="order-10 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Premium Packing</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">We take care of everything from packing to moving and storage, get custom boxes for different objects, PLUS—no extra charge for tape or moving blankets. Never worry about whether your furniture will arrive on time and in perfect condition.</p>
                    <Link to="/premium-packing" className="font-bold m-link-arrow">Learn More</Link>
                  </div>



                  <div className="order-11 mb-12">
                    <h2 className="text-h3 lg:text-h2 font-bold max-w-[423px] mt-16 mb-6">Professional Commercial Moving</h2>
                    <p className="mb-6 text-darkgray max-w-[469px]">If you're moving a business, our commercial movers can help make the transition as smooth as possible. Whatever you need moved—no matter how big or small—we're here to help you get it done.</p>
                    <Link to="/premium-packing" className="font-bold m-link-arrow">Learn More</Link>
                  </div>

                  <div className="order-12 hidden lg:block">
                    <StaticImage src={'../images/services_commercial.png'} layout="fullWidth" />
                  </div>






                </div>


              </div>






            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default ServicesPage
